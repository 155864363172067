<template>
  <th class="i-table-th" :class="{'sortable': column.sortable === false}" @click="changeSort">

    <div class="th-content" :class="{'active': isActiveColumn}">
      <slot>{{ column.label }}</slot>

      <i-tooltip v-if="column?.labelTooltip" :text="column.labelTooltip.text" theme="dark">
        <template v-slot:tooltip-wrapper>
          <i-generate-image :image="column.labelTooltip.icon"/>
        </template>
      </i-tooltip>
      <div v-show="isActiveColumn" class="sort-container" v-if="column.sortable!==false">
        <font-awesome-icon icon="fa-duotone fa-up-long" v-if="pagination.sortDescending === false"/>
        <font-awesome-icon icon="fa-duotone fa-down-long" v-else-if="pagination.sortDescending === true "/>
      </div>
    </div>
  </th>
</template>

<script>
import ITooltip from "@/components/common/ITooltip.vue";
import IGenerateImage from "@/components/common/IGenerateImage.vue";

export default {
  name: 'ITableTh',
  components: {IGenerateImage, ITooltip},
  props: {
    column: {
      type: Object,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
    labelTooltip: {
      type: Object,
    },
  },

  data: () => ({
    columnData: null
  }),

  computed: {
    isActiveColumn() {
      return this.pagination.sortBy === this.columnData.sortBy
    }
  },

  methods: {
    changeSort() {
      if (this.column.sortable !== false) {
        let data = {
          sortBy: this.columnData.sortBy,
          sortDescending: !this.pagination.sortDescending
        }
        this.$emit('updateSort', data);
      }
    },
  },

  created() {
    this.columnData = {
      sortBy: this.column.sortBy ? this.column.sortBy : this.column.name,
      sortDescending: this.pagination.sortDescending
    }
  }

}
</script>

<style lang="scss">
@import "ITableTh";
</style>
