import {GET, POST} from "@/services/gateway";

const listSharedDrives = ({pagination, search, filter}) => {
  return POST('google/shared-drive/list', {pagination, search, filter});
}

const getSharedDriveDetails = (id) => {
  return GET('google/shared-drive/drive', {id})
}

const listSnapshots = ({sharedDriveId, date, dateTo}) => {
  return POST('google/shared-drive-snapshot/snapshot/list', {sharedDriveId, date, dateTo})
}

const createBackup = (sharedDriveId) => {
  return GET('google/drive/shared/backup', {id: sharedDriveId})
}

const exportSnapshot = ({snapshotDateTime, sourceIds, searchTerm, snapshotId, selectedIds, code}) => {
  return POST('google/shared-drive-snapshot/export', {snapshotDateTime, sourceIds, searchTerm, snapshotId, selectedIds}, {code: code ? code : null})
}

const restoreSnapshot = ({snapshotDateTime, sourceIds, searchTerm, destinationId, snapshotId, selectedIds, RestoreType, code}) => {
  return POST('google/shared-drive-snapshot/restore', {snapshotDateTime, sourceIds, searchTerm, destinationId, snapshotId, selectedIds, RestoreType}, {code: code ? code : null});
}

export {
  listSharedDrives,
  getSharedDriveDetails,
  createBackup,
  listSnapshots,
  exportSnapshot,
  restoreSnapshot
}
