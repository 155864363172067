import {defaultPagination} from "@/services/pagination";
import {listCompare, deleteCompare, downloadCompare, cancelCompare, listComparison} from "@/services/api/app/salesforce/recovery/compare/compare";

const defaultState = () => ({
  compares: [],
  pagination: {
    ...defaultPagination(),
    sortBy: 'startedAt'
  },
});

export default {
  namespaced: true,
  actions: {
    listCompare({commit, state}) {
      return listCompare({
        pagination: state.pagination,
        sourceType: 'SALESFORCE',
        dataTypes: ['SALESFORCE'],
      })
        .then(({result, pagination}) => {
          commit('setCompares', result)
          commit('setPagination', pagination)
        })
    },
    deleteCompare({commit, state}, {id}) {
      return deleteCompare(id)
    },
    downloadCompare({commit, state}, {id}) {
      return downloadCompare(id)
    },
    cancelCompare({commit, state}, {id}) {
      return cancelCompare(id, 'salesforce')
    },
    listComparison({commit, state}, id) {
      return listComparison(id)
    },
  },

  mutations: {
    setCompares(state, compares) {
      state.compares = compares;
    },
    setPagination(state, pagination) {
      state.pagination = pagination;
    },

    resetPagination(state, {keepSort}) {
      if (keepSort) {
        state.pagination = {
          ...defaultPagination(),
          sortBy: state.pagination.sortBy,
          sortDescending: state.pagination.sortDescending,
        }
      } else {
        state.pagination = {
          ...defaultPagination(),
          sortBy: 'name',
          sortDescending: false
        };
      }
    },

    resetState(state) {
      Object.assign(state, defaultState())
    }
  },

  state: defaultState(),
}
