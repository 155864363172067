<template>
  <div id="app">
    <router-view/>
    <i-loader :is-loading="isLoading"/>
    <notifications group="foo"/>
  </div>
</template>

<script>
import ILoader from "@/components/common/ILoader.vue";
import {mapState} from "vuex";

export default {
  name: "App",
  computed: {
    ...mapState({
      isLoading: state => state.auth.isLoading,
    }),
  },
  components: {ILoader},
}
</script>

<style lang="scss">
@import "css/app/app";
</style>
