<template>
  <div class="i-user-multi-picker">

    <i-multi-picker
      label-counter="object(s)"
      :label="label"
      :value="value"
      :fetch-options="fetchObjects"
      option-label="objectName"
      option-value="id"
      @input="$val => $emit('input',$val)"
    />
  </div>
</template>

<script>
import {listSnapshotDetails} from "@/services/api/app/salesforce/recovery/salesforceObject/data-set/file";
import IMultiPicker from "@/components/common/forms/IMultiPicker";

export default {
  name: 'IObjectMultiPicker',
  components: {IMultiPicker},
  props: {
    value: [],
    label: {
      type: String,
      default: 'Objects'
    },
    placeholder: {
      type: String,
      default: 'Search all objects'
    },
  },

  data() {
    return {
      selected: null,
      objects: []
    }
  },

  methods: {
    fetchObjects({search}) {
      return listSnapshotDetails({
        ObjectType: '1',
        pagination: {
          pageNumber: 1,
          pageSize: 30,
          sortBy: "objectName",
          sortDescending: false,
        },
        searchTerm: search,
      })
        .then(({result}) => {
          return result
        })
    },
  },


}
</script>

<style lang="scss">
@import "IUserMultiPicker.scss";
</style>
