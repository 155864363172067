import {GET, POST} from "@/services/gateway";

const listSharedFolders = ({pagination, search, filter}) => {
  return POST('dropbox/shared-folder/list', {pagination, search, filter});
}

const getSharedFolderDetails = (id) => {
  return GET('dropbox/shared-folder/folder', {id})
}

const listSnapshots = ({sharedFolderId, date, dateTo}) => {
  return POST('dropbox/shared-folder-snapshot/snapshot/list', {sharedFolderId, date, dateTo})
}

const createBackup = (sharedFolderId) => {
  return GET('dropbox/shared-folder-snapshot/backup', {id: sharedFolderId})
}

const exportSnapshot = ({snapshotDateTime, sourceIds, searchTerm, snapshotId, selectedIds, code}) => {
  return POST('dropbox/shared-folder-snapshot/export', {snapshotDateTime, sourceIds, searchTerm, snapshotId, selectedIds}, {code: code ? code : null})
}

const restoreSnapshot = ({snapshotDateTime, sourceIds, searchTerm, destinationId, snapshotId, selectedIds, RestoreType, code}) => {
  return POST('dropbox/shared-folder-snapshot/restore', {snapshotDateTime, sourceIds, searchTerm, destinationId, snapshotId, selectedIds, RestoreType}, {code: code ? code : null});
}

export {
  listSharedFolders,
  getSharedFolderDetails,
  createBackup,
  listSnapshots,
  exportSnapshot,
  restoreSnapshot
}
