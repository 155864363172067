import {GET, POST} from "@/services/gateway";

const getStats = (data) => {
  const currentDate = new Date();
  data = {
    ...data,
    TimeZoneOffset: currentDate.getTimezoneOffset()
  }
  return POST('dashboard/stats/salesforce', data)
}

export {
  getStats,
}
