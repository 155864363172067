import {
  getUserDetails,
  listSnapshots,
  createBackup,
  exportSnapshot,
  restoreSnapshot,
  compareSnapshot,
} from "@/services/api/app/salesforce/recovery/salesforceObject/object";
import files from "@/store/app/salesforce/recovery/object/data-set/file";
import exports from "@/store/app/salesforce/recovery/object/exports";
import restores from "@/store/app/salesforce/recovery/object/restores";
import backups from "@/store/app/salesforce/recovery/object/backups";
import compares from "@/store/app/salesforce/recovery/object/compares";

const defaultState = () => ({
  userMap: {},
});

export default {
  namespaced: true,
  actions: {
    getUserDetails({commit}, userId) {
      return getUserDetails(userId)
        .then(user => {
          commit('setUser', user);
          return user;
        })
    },
    createBackup({commit}) {
      return createBackup();
    },
    listSnapshots({commit}, {date, dateTo}) {
      return listSnapshots({date, dateTo});
    },
    exportSnapshot({commit}, data) {
      return exportSnapshot(data);
    },
    restoreSnapshot({}, data) {
      return restoreSnapshot(data);
    },
    compareSnapshot({}, data) {
      return compareSnapshot(data);
    },
  },

  mutations: {
    setUser(state, user) {
      state.userMap = {
        ...state.userMap,
        [user.userId]: user
      };
    },
  },

  getters: {
    getUserById: (state) => (id) => {
      return state.userMap[id];
    },
  },

  state: defaultState(),

  modules: {
    files,
    exports,
    restores,
    backups,
    compares
  }
}
