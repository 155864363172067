<template>
  <div class="i-country-picker">
    <i-dropdown
      :name="name"
      :label="label"
      :value="value"
      :options="options"
      @input="onInput"
    />
  </div>
</template>

<script>
import IDropdown from "@/components/common/IDropdown.vue";

const countryList = require('country-list');

export default {
  name: "ICountryPicker",
  components: {IDropdown},
  props: {
    label: {
      type: String
    },
    name: {
      type: String,
      required: true
    },
    value: {
      required: true
    },
  },

  data: () => ({
    options: [],
    countryList
  }),

  methods: {
    onInput(data) {
      this.$emit('input', data)
    }
  },

  created() {
    this.options = countryList.getData().map(country => ({
      label: country.name,
      value: country.code
    }))
  },

}
</script>

<style lang="scss">
@import "ICountryPicker";
</style>
