import {defaultPagination} from "@/services/pagination";
import {listBreadcrumps, listSnapshotDetails} from "@/services/api/app/salesforce/recovery/salesforceObject/data-set/file";
import {listInitialBackupStats} from "@/services/api/app/common/initialBackup";

const defaultState = () => ({
  files: [],
  searchFiles: [],
  searchPagination: {
    ...defaultPagination(),
    sortBy: 'objectName',
    sortDescending: false
  },
  pagination: {
    ...defaultPagination(),
    sortBy: 'objectName',
    sortDescending: false
  },
  folderId: null,
  breadcrumps: [],
  search: null,
  initialSearch: null,
})

export default {
  namespaced: true,

  actions: {
    listSnapshotDetails({commit, state}, data) {
      return listSnapshotDetails({
        ...data,
        pagination: state.pagination,
        searchTerm: data.searchTerm ? data.searchTerm : state?.search?.searchTerm
      })
        .then(({result, pagination}) => {
          commit('setFiles', result)
          commit('setPagination', pagination)
        })
    },
    searchSnapshotDetails({commit}, data) {
      return listSnapshotDetails(data)
        .then(({result, pagination}) => {
          commit('setSearchFiles', result)
          commit('setSearchPagination', pagination)
          return {result, pagination}
        })
    },
    listBreadcrumps({state, commit}, data) {
      if (state.folderId && data.userId && data.snapshotDate) {
        return listBreadcrumps({
          ...data,
          folderId: state.folderId
        })
          .then(result => {
            commit('setBreadcrumps', result);
          })
      } else {
        commit('setBreadcrumps', []);
        return Promise.resolve();
      }
    },
  },

  mutations: {
    setFiles(state, files) {
      state.files = files;
    },
    setPagination(state, pagination) {
      state.pagination = pagination;
    },
    setSearchFiles(state, files) {
      state.searchFiles = files;
    },
    setSearchPagination(state, pagination) {
      state.searchPagination = pagination;
    },
    resetSearchPagination(state) {
      state.searchPagination = {
        ...defaultPagination(),
        sortBy: 'objectName',
        sortDescending: false
      };
    },
    setFolderId(state, folderId) {
      state.folderId = folderId;
    },
    setSearch(state, search) {
      state.search = search
    },
    setBreadcrumps(state, breadcrumps) {
      state.breadcrumps = breadcrumps;
    },
    resetState(state) {
      Object.assign(state, defaultState())
    },
  },

  state: defaultState()
}
