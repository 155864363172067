import salesforce from "@/store/app/salesforce/recovery/object/object";


export default {
  namespaced: true,

  actions: {},

  modules: {
    salesforce,
  }
}
