import {POST, GET, DELETE} from "@/services/gateway";

const listExport = ({pagination, sourceType, dataTypes, dataProvider = 'SALESFORCE'}) => {
  return POST('recovery/export/salesforce-list', {pagination, sourceType, dataTypes, dataProvider})
}

const deleteExport = (id) => {
  return DELETE(`recovery/export`, id)
}

const downloadExport = (id) => {
  return GET(`recovery/export/${id}/download`)
}

const cancelExport = (id) => {
  return GET(`salesforce/export/${id}/cancel`)
}

export {
  listExport,
  downloadExport,
  deleteExport,
  cancelExport,
}
