import {POST, GET, DELETE} from "@/services/gateway";

const listCompare = ({pagination,}) => {
  return POST('recovery/compare/list', {pagination})
}

const listComparison = (id) => {
  return GET(`recovery/compare/${id}/comparison`)
}

const deleteCompare = (id) => {
  return DELETE(`recovery/export`, id)
}

const downloadCompare = (id) => {
  return GET(`recovery/export/${id}/download`)
}

const cancelCompare = (id) => {
  return GET(`salesforce/export/${id}/cancel`)
}

export {
  listCompare,
  downloadCompare,
  deleteCompare,
  cancelCompare,
  listComparison,
}
