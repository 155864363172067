import {GET, POST} from "@/services/gateway";

const listUsers = ({pagination, search, filter}) => {
  return POST('box/user/list', {pagination, search, filter});
}

const getUserDetails = (id) => {
  return GET('box/drive/user', {id})
}

const listSnapshots = ({date, dateTo}) => {
  return POST('salesforce/snapshot/list', {date, dateTo})
}

const createBackup = () => {
  return GET('salesforce/backup')
}

const exportSnapshot = ({snapshotDateTime, sourceIds, searchTerm, snapshotId, selectedIds, extension, dataType = '8', code}) => {
  return POST('salesforce/export', {snapshotDateTime, sourceIds, searchTerm, snapshotId, selectedIds, extension, dataType}, {code: code ? code : null})
}

const restoreSnapshot = ({snapshotDateTime, sourceIds, searchTerm, destinationType, snapshotId, selectedIds, RestoreType, code}) => {
  return POST('salesforce/restore', {snapshotDateTime, sourceIds, searchTerm, destinationType, snapshotId, RestoreType, selectedIds}, {code: code ? code : null});
}

const compareSnapshot = ({sourceIds, selectedIds, objectType, firstSnapshotDateTime, secondSnapshotDateTime, code}) => {
  return POST('salesforce/compare', {sourceIds, selectedIds, objectType, firstSnapshotDateTime, secondSnapshotDateTime}, {code: code ? code : null});
}

export {
  listUsers,
  getUserDetails,
  listSnapshots,
  createBackup,
  exportSnapshot,
  restoreSnapshot,
  compareSnapshot,
}
