<template>
  <div class="form-builder " :class="{[direction] : true}">

    <form class="form-builder-row" @submit.prevent="onSubmit" @keypress.enter.prevent="onSubmit">
      <div v-for="(row, rowIndex) in formConfig.rows" :key="rowIndex" class="dw-form-row">
        <div class="dw-form-builder-title" v-if="row.title">
          {{ row.title }}
        </div>
        <div class="form-builder-block-container" v-show="rowsVisibility[rowIndex]">
          <div class="form-builder-block" v-for="(block, blockIndex) in row.blocks" :key="blockIndex">
            <div class="dw-form-builder-title" v-if="block.title">{{ block.title }}</div>
            <div
              class="form-builder-field-container"
              v-for="(field, fieldIndex) in block.fields"
              :key="field.name"
            >
              <slot :name="`field-${field.name}`">
                <form-builder-field
                  :field="field"
                  :field-value="formModel[field.name]"
                  :initial-field-value="formModel[field.initialValueField]"
                  @input="onInput"
                  @search="onSearch"
                />
              </slot>

              <slot :name="`error-${field.name}`">
                <div class="error-container">
                  <form-builder-field-error
                    v-if="hasError($v.formModel[field.name])"
                    :validation-field="$v.formModel[field.name]"
                    :field-label="field.label"
                    :errors="config.errors? config.errors[field.name]:{}"
                  />
                </div>
              </slot>

            </div>

            <slot name="info-section"></slot>

          </div>
        </div>
      </div>

      <slot name="main-info-section"></slot>

      <slot name="server-error-message">
        <div v-if="errors.length && !customServerError">
          <div class="vs-row server-error-row" v-for="(error, errorIndex) in errors" :key="errorIndex">
            <span class="form-error">{{ error }}</span>
          </div>
        </div>
      </slot>

      <div v-if="customServerError">
        <div class="vs-row server-error-row">
          <span class="form-error">{{ customServerError }}</span>
        </div>
      </div>

      <slot v-if="!hideActions" name="submit" :model="formModel" :submit="onSubmit">
        <div class="button-container" :class="{[buttonPosition]: true}">
          <slot name="cancel"></slot>
          <i-button
            type="submit"
            :class="submitBtnClass"
            :label="buttonLabel"
            :is-loading="isLoading || loadingButton"
            :is-disabled="isLoading || hasAnyError() || disabledButton"
          />
        </div>
      </slot>

      <slot name="info-message">
        <div class="info-message" v-if="infoMessage">
          {{ infoMessage }}
        </div>
      </slot>

    </form>

  </div>
</template>

<script>
import FormBuilderField from "./FormBuilderField.vue";
import FormBuilderFieldError from "./FormBuilderFieldError.vue";
import {generateFormModel} from "@/services/forms";
import IButton from "@/components/common/IButton.vue";

export default {
  name: 'FormBuilder',
  components: {
    IButton,
    FormBuilderFieldError,
    FormBuilderField,
  },
  props: {
    config: {
      type: Object,
      required: true
    },
    value: {
      type: Object
    },
    hideActions: {
      type: Boolean,
      default: false
    },
    submitAction: {
      type: Function
    },
    successMessage: {
      type: String,
    },
    buttonLabel: {
      type: String,
      default: 'Save'
    },
    resetOnSuccess: {
      type: Boolean,
      default: false
    },
    validateOnInput: {
      type: Boolean,
      default: false
    },
    direction: {
      type: String,
      default: 'vertical' // vertical|horizontal
    },
    buttonPosition: {
      type: String,
      default: 'center' // right|left|
    },
    submitBtnClass: {
      type: String
    },
    infoMessage: {
      type: String,
    },
    customServerError: {
      type: String,
    },
    disabledButton: {
      type: Boolean,
      default: false,
    },
    loadingButton: {
      type: Boolean,
      default: false
    },
    autoUpdateFormModel: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    formModel: {},
    isLoading: false,
    error: null,
    errors: [],
    toggleRows: {}
  }),

  watch: {
    value: {
      deep: true,
      handler(newValue) {
        if (this.autoUpdateFormModel) {
          this.formModel = { ...newValue };
        }
      },
    },
  },

  validations() {
    let validations = this.formConfig.validations || {};
    return {
      formModel: validations
    }
  },

  computed: {
    rowsVisibility() {
      let configRows = this.config.rows.reduce((acc, row, index) => ({
        ...acc,
        [index]: row.toggle ? false : true
      }), {});
      return {
        ...configRows,
        ...this.toggleRows
      }
    },
    formConfig() {

      const fieldsVisibility = this.config.rows.reduce((allFields, row) => {
        return {
          ...allFields,
          ...row.blocks.reduce((allBlockFields, block) => {
            return {
              ...allBlockFields,
              ...block.fields.reduce((fields, field) => {

                return {
                  ...fields,
                  [field.name]: field.isVisible === undefined ? true : field.isVisible(this.formModel)
                }
              }, {})
            }
          }, {})
        }
      }, {});
      const rows = this.config.rows.reduce((allRows, row) => {
        const blocks = row.blocks.reduce((rowBlocks, block) => {

          const fields = (block.fields || []).filter(field => {
            return fieldsVisibility[field.name]
          })
          if (fields.length) {
            return [
              ...rowBlocks,
              {
                ...block,
                fields
              }
            ]
          } else {
            return rowBlocks
          }
        }, []);
        if (blocks.length) {
          return [
            ...allRows,
            {
              ...row,
              blocks
            }
          ]
        }
      }, []);

      const validations = Object.keys(this.config.validations || {}).reduce((filteredValidations, fieldName) => {
        return {
          ...filteredValidations,
          ...fieldsVisibility[fieldName] && {[fieldName]: this.config.validations[fieldName]}
        }
      }, {});


      return {
        ...this.config,
        rows,
        validations
      }
    }
  },

  methods: {
    onInput({value, field}) {
      this.errors = [];
      this.formModel = {
        ...this.formModel,
        [field]: value
      };
      this.$emit('input', this.formModel);
      this.$emit('inputField', {value, field, formModel: this.formModel});

      if (this.validateOnInput) {
        this.validate()
      }
    },

    onTouch() {
      this.$v.$touch();
    },

    resetForm() {
      this.$v.$reset();
    },

    onSubmit() {
      this.$v.$touch();
      if (this.$v.$error || this.disabledButton) {
        return;
      }

      if (typeof this.submitAction === "function") {
        this.isLoading = true;
        this.submitAction(this.formModel)
          .then(() => {
            this.errors = []
            this.isLoading = false;

            if (this.successMessage) {
              this.$notifySuccess(this.successMessage);
            }
            if (this.resetOnSuccess) {
              this.formModel = {};
              this.$v.$reset();
            }
          })
          .catch(error => {
            try {
              if (!error.message) {
                this.errors = Object.values(error).map((err) => {
                  return err[0]
                });
              } else if (error.graphQLErrors.length) {
                this.errors = [];
                error.graphQLErrors.forEach((err) => {
                  this.errors = [
                    ...this.errors,
                    err.message
                  ]
                })
              } else if (error?.message) {
                this.errors = [error.message]
              }
            } catch (e) {
              this.errors = ['Something went wrong']
            }

            this.isLoading = false;
          });
      } else {
        this.$emit('submit', this.formModel)
        // this.$notifyError("FORM IS DEPRECATED")
      }

    },
    hasError(validation) {
      return validation
        && validation.$error
    },
    hasAnyError() {
      return this.$v.$error;
    },

    onSearch({value, field}) {
      this.$emit('search', {value, field});
    },
    setLoading(loading) {
      this.isLoading = loading
    },
    forceUpdateFormModel() {
      this.formModel = {
        ...this.value
      } || generateFormModel(this.formConfig)
    },
    validate() {
      this.$v.$touch();
      return this.$v.$error === false
    },

  },

  created() {
    this.formModel = {
      ...this.value
    } || generateFormModel(this.formConfig)
  },

}
</script>

<style lang="scss">
@import "FormBuilder";
</style>
