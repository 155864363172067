<template>
  <div class="i-multi-picker">

    <div class="field-label" v-if="label">
      {{ label }}
    </div>
    <multiselect
        :value="value"
        :label="optionLabel"
        :trackBy="optionValue"
        :options="options"
        :multiple="true"
        :placeholder="placeholder"
        :close-on-select="false"
        :clear-on-select="false"
        @open="onClick"
        @search-change="onSearch"
    >

      <template v-slot:tag="{remove,option}">
        <div v-if="value && value.length && value[0][optionValue] === option[optionValue]">
          Selected {{ value.length }} {{ labelCounter }}
        </div>
        <div v-else style="display: none"></div>
      </template>

      <template v-slot:option="{option}">
        <div class="checkbox-i-multi-picker" @click.prevent="toggleSelect(option)">
          {{ option?.name ? option?.name : option?.objectName }}
          <div class="check-box-custom" :class="{'checked': selectedValues.includes(option[optionValue])}"></div>
        </div>
      </template>

    </multiselect>
  </div>

</template>

<script>
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

export default {
  name: "IMultiPicker",
  components: {Multiselect},
  props: {
    value: {
      type: Array,
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    fetchOptions: {
      type: Function,
      required: true
    },
    optionLabel: {
      type: String,
      default: 'name'
    },
    optionValue: {
      type: String,
      default: 'id'
    },
    labelCounter: {
      type: String,
      default: 'user(s)'
    }
  },

  data: () => (
      {
        selected: [],
        options: [],
      }),

  computed: {
    selectedValues() {
      return this.value.map(selectedOption => selectedOption[this.optionValue]);
    },
    allOptions() {
      return [
        ...this.value,
        ...this.options.filter(option => !this.selectedValues.includes(option[this.optionValue]))
      ]
    }
  },

  methods: {
    isValueSelected(option) {
      return this.value.find(value => value[this.optionValue] === option[this.optionValue]) !== undefined
    },
    sortOptions(options) {
      return [
        ...this.value,
        ...options.filter(option => !this.selectedValues.includes(option[this.optionValue])),
      ]
    },
    onClick() {
      this.options = this.sortOptions(this.options)
    },
    onSearch(searchTerm) {

      return this.fetchOptions({search: searchTerm?.trim()?.length ? searchTerm?.trim() : null})
          .then(options => {
            this.options = this.sortOptions(options);
          })
    },
    onSelect(data) {
      this.$emit('input', [
        ...this.value,
        data
      ])
    },
    onRemove(data) {
      this.$emit('input', this.value.filter(val => val[this.optionValue] !== data[this.optionValue]))
    },
    toggleSelect(option) {
      if (this.isValueSelected(option)) {
        this.onRemove(option)
      } else {
        this.onSelect(option)
      }
    }
  },
  created() {
    this.onSearch()
  }

}
</script>

<style lang="scss">
@import "IMultiPicker";
</style>
