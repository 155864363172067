import {GET, POST} from "@/services/gateway";

const listRestores = ({pagination, sourceType, dataTypes, dataProvider = 'SALESFORCE'}) => {
  return POST('recovery/restore/salesforce-list', {pagination, sourceType, dataTypes, dataProvider})
}

const cancelRestore = (id) => {
  return GET(`salesforce/restore/${id}/cancel`)
}

export {
  listRestores,
  cancelRestore,
}
