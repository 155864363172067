<template>
    <div class="i-empty-data">
        <img :src="getIcon" alt="Empty data">
        <span>{{ emptyText }}</span>
        <span v-if="emptySubText" class="sub-text">{{ emptySubText }}</span>
    </div>
</template>

<script>

export default {
    name: 'IEmptyData',

    props: {
        emptyIcon: {
            type: String,
            default: 'nofiles-empty.svg'
        },
        emptyText: {
            type: String,
            default: 'No data found!'
        },
        emptySubText: {
            type: String,
        },
    },
    computed: {
        getIcon() {
            return require(`../../assets/empty-table/${this.emptyIcon}`)
        }
    }

}
</script>

<style lang="scss">
@import "IEmptyData";
</style>
