<template>
  <div class="i-table">
    <div class="i-table-container" :class="stickyClass">
      <table id="table">
        <thead :class="stickyClass">
        <tr>
          <i-table-th
            v-for="(column, index) in columns"
            :key="column.name"
            :column="column"
            :pagination="pagination"
            :label-tooltip="labelTooltip"
            @updateSort="reload"
          >
            <template v-slot:default v-if="index === 0 && column.type === 'folder'">
              <input
                type="checkbox"
                class="checkbox"
                :checked="isCheck"
                @click="onCheckAll"
              />

              {{ column.label }}
            </template>
          </i-table-th>

        </tr>
        </thead>
        <tbody>
        <i-loader :is-loading="isLoading"/>
        <tr :class="{'clickable': isClickable(item)}"
            v-for="item in data"
            :key="item[rowKey]"
            @click="onRowClick(item)"
        >
          <td
            :class="{'with-actions': column.actions}"
            v-for="column in columns"
            :key="column.name"
          >

            <slot :name="column.name" :item="item">

              <span v-if="column.type === 'date-time'">
                  {{
                  getFieldValue(item, column.name) | formatDateTime | iEmptyData(item.type === 'Folder' || item.type === 'Channel')
                }}
              </span>

              <span v-else-if="column.type === 'interval-duration'">
                  <span>{{ intervalDuration(item) }}</span>
              </span>

              <div v-else-if="column.type === 'channel'" class="folder-label">

              </div>

              <div v-else-if="column.type === 'folder'" class="folder-label">
                <div v-if="item?.type == 'Channel'" class="channel">
                  <div class="wrapper">
                    <input
                      type="checkbox"
                      class="checkbox"
                      :checked="checkedRows[item[rowKey]]"
                      @click.stop="onCheck(item)"
                    >
                    <img
                      src="../../../assets/app/icons/folder.svg"
                      class="folder-img"
                      alt="folder icon"
                    >
                  </div>

                  <div class="name-container">
                    <div class="name">
                      <router-link
                        v-if="enableNewTab"
                        :to="channelRoutePath(item.channelId)"
                      >
                        {{ item[column.name] | iEmptyData(item.type === 'Channel') }}
                      </router-link>
                      <span v-else>
                        {{ item[column.name] | iEmptyData(item.type === 'Channel') }}
                      </span>
                    </div>

                    <div v-if="showPath" class="path">
                      <div class="value">
                        {{ item[pathKey] | iEmptyData(item.type === 'Channel') }}
                      </div>

                      <span v-if="item[pathKey] || item.type !== 'Channel'" class="tooltiptext">
                        {{ item[pathKey] | iEmptyData(item.type === 'Channel') }}
                      </span>
                    </div>
                  </div>
                </div>

                <div v-else class="folder">
                  <div class="wrapper">
                    <input
                      type="checkbox"
                      class="checkbox"
                      :checked="checkedRows[item[rowKey]]"
                      @click.stop="onCheck(item)"
                    >
                    <img
                      v-if="item.type==='Folder' && !isConversationsTable"
                      src="../../../assets/app/icons/folder.svg"
                      class="folder-img"
                      alt="folder icon"
                    >
                    <img
                      v-else-if="item.type==='Folder' && isConversationsTable"
                      :src="require(`../../../assets/app/icons/chat.svg`)"
                      class="folder-img"
                      alt="folder icon"
                    >
                    <div v-else-if="column.fileIconType">
                      <img
                        v-if="column.fileIconType === 'calendar'"
                        :src="item.isRecurring ? require(`../../../assets/app/icons/recurring_calendar.svg`) : require(`../../../assets/app/icons/${column.fileIconType}-table.svg`)"
                        class="file-icon"
                        alt="file icon"
                      >
                      <img
                        v-else
                        :src="require(`../../../assets/app/icons/${column.fileIconType}-table.svg`)"
                        class="file-icon"
                        alt="file icon"
                      >
                    </div>
                    <span v-else-if="column.name = 'type'">

                    </span>
                    <i-bubble-color v-else>
                      <template v-slot:bubble-content>
                        <font-awesome-icon v-if="item.type==='Folder'" icon="fa-duotone fa-folder"/>
                        <span v-else>{{ item[column.name].substring(0, 1) }}</span>
                      </template>
                    </i-bubble-color>
                  </div>

                  <div class="name-container">
                    <div class="name">
                      <router-link
                        v-if="(item.type === 'Folder' || item.type === 'Channel') && enableNewTab"
                        :to="routePath(item.folderId)"
                      >
                        {{ item[column.name] | iEmptyData(item.type === 'Folder') }}
                      </router-link>
                      <span v-else-if="column.name = 'type'">
                        <span v-if="item[column.name] === 2">Metadata</span>
                        <span v-else>Data</span>
                      </span>

                      <span v-else>
                        {{ item[column.name] | iEmptyData(item.type === 'Folder') }}
                      </span>
                    </div>

                    <div v-if="showPath" class="path">
                      <div class="value">
                        {{ item[pathKey] | iEmptyData(item.type === 'Folder') }}
                      </div>

                      <span v-if="item[pathKey] || item.type !== 'Folder'" class="tooltiptext">
                        {{ item[pathKey] | iEmptyData(item.type === 'Folder') }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <span v-else-if="column.name === 'name'" class="name-label">
                {{ truncateName(item[column.name]) | iEmptyData(item.type === 'Folder') }}
              </span>

              <span v-else-if="column.type === 'taskStatus'">
                {{ getTaskStatus(item[column.name]) | iEmptyData(item.type === 'Folder') }}
              </span>

              <span v-else-if="column.type === 'status'">
                <span v-if="item[column.name] === 1">Pending</span>
                <span v-else-if="item[column.name] === 2">In progress</span>
                <span v-else-if="item[column.name] === 3">Completed</span>
                <span v-else-if="item[column.name] === 4">Failed</span>
                <span v-else>NA</span>
              </span>
              <!--OLD CODE-->
              <!--              <span v-else-if="column.type === 'checkbox'">-->
              <!--                <input type="checkbox" @click.stop="onCheck(item)" :checked="checkedRows[item[rowKey]]">-->
              <!--              </span>-->

              <span v-else-if="column.name === 'status' && column.type === 'enum'">
                {{ getJobStatus(item[column.name]) | iEmptyData(item.type === 'Folder') }}
              </span>

              <span v-else-if="column.type === 'enum'">
                  <span v-if="item[column.name]">{{
                      item[column.name] | iCapitalizeString | iSharedDrivesConverter(column) | iSharedFoldersConverter(column)
                    }}</span>
                  <span v-else>NA</span>
              </span>

              <span v-else-if="column.name === 'status'">
                {{ getStatus(item[column.name]) | iEmptyData(item.type === 'Folder') }}
              </span>

              <span v-else-if="column.name === 'state'" class="state-label">
                {{ item[column.name] | iCapitalizeString | iEmptyData(item.type === 'Folder') }}
              </span>

              <span v-else-if="column.name === 'dataSet'" class="data-set-label">
                {{ getDataSetLabel(item[column.name]) }}
              </span>

              <span v-else-if="column.type === 'objectType'">
                <span v-if="item[column.name] === 2">Metadata</span>
                <span v-else>Data</span>
              </span>

              <span v-else-if="column.type === 'converted-size'">
                <span v-if="item.status === 'COMPLETED' || item.status === 'DELETED'">
                  {{ item[column.name] | iConvertItemsSize }}
                </span>
                <span v-else>
                  NA
                </span>
              </span>

              <span v-else-if="column.type === 'items'">
                <span v-if="item.status === 'COMPLETED' || item.status === 'DELETED' || item.status === 'PROGRESS'">
                  {{ item[column.name] | iEmptyData(item.type === 'Folder') }}
                </span>
                <span v-else>
                  NA
                </span>
              </span>

              <span v-else-if="column.type === 'totalSize' || column.name.startsWith('size.')">
                <span v-if="item.type != 'Folder'">
                  {{ getFieldValue(item, column.name) | iConvertSize | iEmptyData(item.type === 'Folder') }}
                </span>

              </span>

              <span v-else-if="column.type === 'backup'">
                {{ getTypeName(item[column.name]) | iEmptyData(item.type === 'Folder') }}
              </span>

              <span v-else-if="column.type === 'reporting-module'">
                {{
                  getReportingModuleName(item[column.name], item?.provider || 1) | iEmptyData(item.type === 'Folder')
                }}
              </span>

              <span v-else-if="column.type === 'backup-job-module'">
                {{
                  getBackupJobModule(item[column.name], item.provider, item.sourceType) | iEmptyData(item.type === 'Folder')
                }}
              </span>

              <span v-else-if="column.name === 'size'">
                {{ item[column.name] | iConvertSize | iEmptyData(item.type === 'Folder') }}
                <!--                check-->
                <!--                <div v-if="item[column.name] < 1">NA</div>-->
              </span>

              <span v-else>
                {{ getFieldValue(item, column.name) | iEmptyData(item.type === 'Folder' || item.type === 'Channel') }}
              </span>

              <i-action-links
                v-if="column.actions && item.status !== 5 && item.status !== 3"
                v-bind="item"
                :is-disabled="!isClickable(item)"
                :items="column.actions"
              />

              <span v-if="column.name === 'actionsMenu'">
                <i-actions-menu
                  :row-item="item"
                  :actions-menu="actionsMenu"
                  @onMenuItemClick="onMenuItemClick"
                />
              </span>
            </slot>
          </td>
        </tr>
        <tr v-if="!isLoading && (!data || !data.length)" class="no-data-result">
          <td style="text-align: center" :colspan="columns.length">
            <i-empty-data :empty-text="emptyText" :empty-icon="emptyIcon" :empty-sub-text="emptySubText"/>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <i-table-pagination
      v-if="!hidePagination"
      :pagination="pagination"
      @updatePagination="updatePagination"
    />
  </div>
</template>

<script>
import ITableTh from "@/components/common/table/ITableTh";
import ITablePagination from "@/components/common/table/ITablePagination";
import {
  getBackupJobModule,
  getDataSetLabel,
  getJobStatus,
  getTypeName,
  getEnumLabel,
  getReportingModuleName,
  getStatus,
  getTaskStatus,
} from "@/services/enums";
import {defaultPagination} from "@/services/pagination";
import IBubbleColor from "@/components/common/table/IBubbleColor";
import {formatDuration, intervalToDuration} from "date-fns";
import ILoader from "@/components/common/ILoader.vue";
import ICheckbox from "@/components/common/forms/ICheckbox";
import IActionLinks from "@/components/common/table/IActionLinks";
import IEmptyData from "@/components/common/IEmptyData.vue";
import IActionsMenu from "@/components/common/table/IActionsMenu";

export default {
  name: 'ITable',
  components: {IActionsMenu, IEmptyData, IActionLinks, ICheckbox, ILoader, IBubbleColor, ITablePagination, ITableTh},
  props: {
    data: {
      type: Array,
      default: () => ([])
    },
    columns: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      required: true
    },
    search: {
      type: String,
    },
    filter: {
      type: Object
    },
    reloadDataAction: {
      type: Function
    },
    isClickableRow: {
      type: Function
    },
    hidePagination: {
      type: Boolean,
      default: false
    },
    loadOnInit: {
      type: Boolean,
      default: true
    },
    clickable: {
      type: Boolean,
      default: false
    },
    isSticky: {
      type: Boolean,
      default: false,
    },
    rowKey: {
      type: String,
      default: 'id'
    },
    objectId: {
      type: String
    },
    selectedRows: {
      type: Array
    },
    showPath: {
      type: Boolean,
      default: false
    },
    pathKey: {
      type: String,
      default: 'prettyPath'
    },
    labelTooltip: {
      type: Object
    },
    emptyIcon: {
      type: String,
      default: 'nofiles-empty.svg'
    },
    emptyText: {
      type: String,
      default: 'No data found!'
    },
    emptySubText: {
      type: String,
    },
    enableNewTab: {
      type: Boolean,
      default: false
    },
    isConversationsTable: {
      type: Boolean,
      default: false
    },
    actionsMenu: {
      type: Array
    }
  },

  data: () => ({
    isLoading: true,
    stickyClass: '',
    isCheck: false,
  }),

  computed: {
    checkedRows() {
      if (this.selectedRows) {
        return this.selectedRows.reduce((acc, rowId) => {
          return {
            ...acc,
            [rowId]: rowId
          }
        }, {})
      }

      return {}
    },
  },

  methods: {
    getEnumLabel,
    getDataSetLabel,
    getStatus,
    getTaskStatus,
    getJobStatus,
    getTypeName,
    getReportingModuleName,
    getBackupJobModule,
    reload(column) {
      this.$emit('changePagination', {
        pagination: {
          ...this.pagination,
          pageNumber: 1,
          sortBy: column.sortBy,
          sortDescending: column.sortDescending
        }
      })
    },
    truncateName(name) {
      return name.length > 35 ? name.substring(0, 35) + '...' : name;
    },

    updatePagination(pagination) {
      this.$emit('changePagination', {pagination})
    },

    modifySelectedRows(item) {

      let rowKey = this.rowKey;
      if (this.objectId) {
        rowKey = this.objectId;
      }

      let selectedRows = [...this.selectedRows];
      if (!selectedRows.includes(item[rowKey])) {
        selectedRows.push(item[rowKey]);
      } else {
        let selectedId = selectedRows.indexOf(item[rowKey]);
        if (selectedId > -1) {
          selectedRows.splice(selectedId, 1);
        }
      }

      return selectedRows
    },

    onCheckAll() {
      this.isCheck = !this.isCheck;
      let selectedRows = [...this.selectedRows];

      if (this.isCheck) {

        this.data.forEach(item => {
          if (!selectedRows.includes(item[this.rowKey])) {
            selectedRows.push(item[this.rowKey]);
          }
        })
      } else {
        this.data.forEach(item => {
          let selectedId = selectedRows.indexOf(item[this.rowKey]);
          if (selectedId > -1) {
            selectedRows.splice(selectedId, 1);
          }
        })
      }
      this.$emit('clickCheck', selectedRows);
    },

    async onCheck(item) {
      let selectedRows = this.modifySelectedRows(item);
      this.$emit('clickCheck', selectedRows);
      await this.$nextTick();
      this.isAllCheck();
    },

    reloadItems() {
      this.isLoading = true;
      if (this.reloadDataAction) {
        return this.reloadDataAction({
          pagination: this.pagination,
          search: this.search,
          filter: this.filter
        })
          .finally(() => {
            this.isLoading = false;
          })
      }
    },
    isAllCheck() {
      let notChecked = [];
      if (this.data?.length > 0) {
        notChecked = this.data.find(selectedRow => {
            return (!this.selectedRows?.includes(selectedRow[this.rowKey]));
          }
        )
      }

      this.isCheck = !notChecked;
    },
    onRowClick(item) {
      if (item.type === 'Email') {
        this.$emit('clickEmail', item)
      } else if (this.isClickable(item)) {
        this.$emit('clickRow', item)
      }
    },
    isClickable(item) {
      if (item !== undefined) {
        return (!this.isClickableRow || this.isClickableRow(item)) && (this.clickable || (this.$listeners['clickRow'] && (item.type === 'Folder' || item.type === 'Channel' || item.type === 'Email')))
      } else {
        return this.clickable || (this.$listeners['clickRow'] && (item.type === 'Folder' || item.type === 'Channel'))
      }
    },
    intervalDuration(item) {
      if (item.endedAt && item.startedAt) {
        let timeTaken = intervalToDuration({
          start: new Date(item.startedAt),
          end: new Date(item.endedAt)
        })
        if (formatDuration(timeTaken)) {
          return formatDuration(timeTaken)
        }
        return "0 seconds"
      }
      return "NA"
    },
    // need to be deleted
    // getSize(item, field) {
    //   const parts = field.split('.');
    //   if (item && item.size) {
    //     if (item[parts[0]][parts[1]] && item[parts[0]][parts[1]] > 0) {
    //       return `${(item[parts[0]][parts[1]]).toFixed(3)} MB`;
    //     }
    //
    //     return '/'
    //   }
    //
    //   return '/';
    // },
    getFieldValue(row, field) {
      let tree = field.split('.')
      if (tree.length === 1) {
        return row[field]
      }
      let value = row;
      for (let i = 0; i < tree.length; i++) {
        if (value) {
          value = value[tree[i]]
        }
      }
      return value;
    },

    handleScroll() {
      let table = document.getElementById("table");
      let pageScrollOffset = window.pageYOffset;
      let tBodyOffsetTop = 0;
      tBodyOffsetTop += (table.offsetTop - table.scrollTop + table.clientTop);

      if (pageScrollOffset >= (tBodyOffsetTop - 100)) {
        this.stickyClass = 'sticky'
      } else {
        this.stickyClass = ''
      }

    },
    routePath(id) {
      return {
        name: `${this.$route.name}`,
        params: {
          ...this.$route.params,
          folderId: id
        }
      }
    },
    channelRoutePath(id) {
      return {
        name: `${this.$route.name}`,
        params: {
          channelId: id
        }
      }
    },
    setIsLoading(val) {
      this.isLoading = val;
    },
    onMenuItemClick({row, action}) {
      this.$emit(action, row);
    },
  },

  watch: {
    data(newData) {
      this.isAllCheck(newData)
    }
  },

  created() {
    if (this.isSticky) {
      window.addEventListener("scroll", this.handleScroll);
    }
    if (this.loadOnInit) {
      this.reloadItems({
        pagination: defaultPagination()
      })
    }
  },

  beforeDestroy() {
    if (this.isSticky) {
      window.removeEventListener("scroll", this.handleScroll);
    }
  }
}
</script>

<style lang="scss">
@import "ITable";
</style>
