<template>
  <div class="i-team-multi-picker">

    <i-multi-picker
        label-counter="shared drive(s)"
        :label="label"
        :value="value"
        :fetch-options="fetchSharedDrives"
        option-label="name"
        option-value="id"
        @input="$val => $emit('input',$val)"
    />
  </div>
</template>

<script>
import {listSharedDrives} from "@/services/api/google/recovery/shared-drive/sharedDrive";
import {defaultPagination} from "@/services/pagination";
import IMultiPicker from "@/components/common/forms/IMultiPicker.vue";

export default {
  name: 'ISharedDriveMultiPicker',
  components: {IMultiPicker},
  props: {
    value: {},
    label: {
      type: String,
      default: "Shared drives"
    },
    placeholder: {
      type: String,
      default: 'Search all shared drives'
    },
  },

  data() {
    return {
      selected: null,
      sharedDrives: []
    }
  },

  methods: {
    fetchSharedDrives({search}) {

      return listSharedDrives({
        pagination: {
          pageNumber: 1,
          pageSize: 30,
          sortBy: 'name',
          sortDescending: false
        },
        search,
        filter: {
          "statuses": [
            1, 2, 4
          ]
        }
      })
          .then(({result}) => {
            return result
          })
    },
  },

}
</script>

<style lang="scss">
@import "IUserMultiPicker";
</style>
